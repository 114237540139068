const scopes = {
  RoleManagement: "role_mgmt",
  Dashboard: "dshb",
  CreateCustomer: "customer",
  Reports: "rpt",
  PostJob: "jobs_post",
  CandidateSearch: "cdt_srch",
  AccountManagement: "acc_mgmt",
  Tickets: "tcts",
  Alerts: "alrt",
  Notification: "ntfcn",
  ToolsSkillTaxonomy: "tol_skltxnmy",
  ToolsCandidateList: "tol_cdtlst",
  ToolsCandidateSearchByEmail: "tol_cdtsrch_mail",
  Analytics: "anal",
  WhatsNew: "whats_new",
  CandidateFeedback: "cdt_fb",
  UserManagement: "usr_mgmt",
  JDList: "jobs_lst",
  BillingAndSubscription: "bill_subs",
  MyProfile: "my_profile",
  Support: "sprt_hist",
  CandidateList: "cdt_lst",
  SearchCandidates: "srch_cdts",
  UploadProfiles: "load_profile",
  ReferYourFriends: "rfr_frnds",
  Jobs: "jobs",
  ResumeBuilder: "build_cv",
  Assessment: "asmt",
  package: "pckg",
  profile: "profile",
  assessment: "asmt"
};

const permissions = {
  create: "c",
  read: "r",
  transfer: "t",
  edit: "e",
  delete: "d",
  buy: "b",
  download: "dw",
  approve: "a",
  reject: "rj",
};

const npermissions = {
  c: "create",
  r: "read",
  t: "transfer",
  e: "edit",
  d: "delete",
  b: "buy",
  dw: "download",
  a: "approve",
  rj: "reject",
};

const nscopes = {
  role_mgmt: "RoleManagement",
  dshb: "Dashboard",
  customer: "CreateCustomer",
  rpt: "Reports",
  jobs_post: "PostJob",
  cdt_srch: "CandidateSearch",
  acc_mgmt: "AccountManagement",
  tcts: "Tickets",
  alrt: "Alerts",
  ntfcn: "Notification",
  tol_skltxnmy: "ToolsSkillTaxonomy",
  tol_cdtlst: "ToolsCandidateList",
  tol_cdtsrch_mail: "ToolsCandidateSearchByEmail",
  anal: "Analytics",
  whats_new: "WhatsNew",
  cdt_fb: "CandidateFeedback",
  usr_mgmt: "UserManagement",
  jobs_lst: "JDList",
  bill_subs: "BillingAndSubscription",
  my_profile: "MyProfile",
  sprt_hist: "Support",
  cdt_lst: "CandidateList",
  srch_cdts: "SearchCandidates",
  load_profile: "UploadProfiles",
  rfr_frnds: "ReferYourFriends",
  jobs: "Jobs",
  build_cv: "ResumeBuilder",
  asmt: "Assessment",
  pckg: "package",
  profile: "profile",
};

export function getScope(scopeName, permission) {
  if (scopes[scopeName] && permissions[permission])
    return scopes[scopeName] + "_" + permissions[permission];
  else return null;
}

export function createScopePermissions(scope) {
  let finalScope = {};
  if (scope) {
    let scopeArray = scope.split(" ");
    scopeArray.forEach((x) => {
      let i = x.lastIndexOf("_");
      let moduleName = nscopes[x.substring(0, i)];
      let newObj = {};
      let permission = npermissions[x.substring(i + 1)];
      newObj[permission] = true;
      if (finalScope[moduleName]) {
        finalScope[moduleName] = { ...finalScope[moduleName], ...newObj };
      } else {
        finalScope[moduleName] = newObj;
      }
    });
  }
  return finalScope;
}

import { lazy } from "react";
import QuickResume from "../pages/QuickResume";
import SearchJobs from "../pages/SearchJobs";
// import RoleManagement from "../pages/Admin/RoleManagement"
import ListingRole from "../pages/RoleManagement/ListingRole";
import ListingUsers from "../pages/RoleManagement/ListingUsers";

// use lazy for better code splitting, a.k.a. load faster
const RecruiterDashboard = lazy(() => import("../pages/Recruiter/Dashboard"));
const Profile = lazy(() => import("../pages/Profile"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const MyInterview = lazy(() => import("../pages/MyInterview"));
const Jobs = lazy(() => import("../pages/Jobs"));
const Interviews = lazy(() => import("../pages/Interviews"));
const SupportHistory = lazy(() => import("../pages/SupportHistory"));
const AdminSupportHistory = lazy(() => import("../pages/Admin/LoginSupport"));
const AdminSalesTicket = lazy(() => import("../pages/Admin/SalesTicket"));
const AdminDashboard = lazy(() => import("../pages/Admin/Dashboard"));
const SkillTaxonomy = lazy(() => import("../pages/Admin/SkillTaxonomy/Dashboard"));
const ViewSkills = lazy(() => import("../pages/Admin/SkillTaxonomy/ViewSkills"));
const ReviewTaxonomy = lazy(() => import("../pages/Admin/SkillTaxonomy/ReviewTaxonomy"));
const UploadSkill = lazy(() => import("../pages/Admin/SkillTaxonomy/UploadSkill"));
const AddSkill = lazy(() => import("../pages/Admin/SkillTaxonomy/AddSkill"));
const AddCategory = lazy(() => import("../pages/Admin/SkillTaxonomy/AddCategory"));
const RecruiterSupportHistory = lazy(() =>
  import("../pages/Recruiter/RecruiterSupportHistory")
);
const AddUser = lazy(() => import("../pages/RoleManagement/AddUser"));
const EditUser = lazy(() => import("../pages/RoleManagement/EditUser"));
const JobList = lazy(() => import("../pages/RecruiterJobList"));
const JobPostingForm = lazy(() => import("../components/Forms/Recruiter/JobPostingForm"));
const UserManagement = lazy(() => import("../pages/Recruiter/UserManagement"));
const YourProfile = lazy(() => import("../pages/Recruiter/YourProfile"));
const BillingPage = lazy(() => import("../pages/Recruiter/BillingPage"));
const RecruiterReportsTab = lazy(() => import("../pages/Recruiter/ReportsTab"));
const AdminReportsTab = lazy(() => import("../pages/Admin/ReportsTab"));
const CandidateProfileSearch = lazy(() =>
  import("../pages/Admin/CandidateProfileSearch")
);
const WhatsNew = lazy(() => import("../pages/Admin/What's_new"));

const RecommendedCandidateList = lazy(() =>
  import("../pages/Recruiter/RecommendedCandidateList")
);
const RecommendedCandidateMainTabs = lazy(() =>
  import("../pages/Recruiter/RecommendedCandidateMainTabs")
);
const AccountManagement = lazy(() => import("../pages/Admin/AccountManagement"));
const AdminAccountManagement = lazy(() =>
  import("../pages/Admin/AdminAccountManagement")
);
const AddNewRole = lazy(() => import("../pages/RoleManagement/AddNewRole"));
const EditNewRole = lazy(() => import("../pages/RoleManagement/EditNewRole"));
const CandidateList = lazy(() => import("../pages/Admin/CandidateList"));
const RecruiterSupport = lazy(() => import("../pages/Admin/RecruiterSupport"));
const CreateCustomer = lazy(() => import("../pages/Admin/CreateCustomer"));
const Alerts = lazy(() => import("../pages/Admin/Alerts"));
const AdminJobPosting = lazy(() => import("../components/Forms/Admin/AdminJobPosting"));
const AdminJobList = lazy(() => import("../pages/Admin/JobList"));
const CandidateSearch = lazy(() => import("../pages/Recruiter/CandidateSearch"));
const CandidateSearchEmail = lazy(() => import("../pages/Admin/SearchCandidateEmail"));
const SubscriptionPlan = lazy(() => import("../pages/Recruiter/SubscriptionPlan"));
const Success = lazy(() => import("../pages/PaymentSuccess"));
const Error = lazy(() => import("../pages/PaymentError"));
const Invites = lazy(() => import("../pages/Invites"));
const SupportTickets = lazy(() => import("../pages/Admin/SupportTickets"));
const ReferYourFriends = lazy(() => import("../pages/ReferYourFriends"));
const InterviewTips = lazy(() => import("../pages/InterviewTips"));
const FreeResources = lazy(() => import("../pages/FreeResources"));
const PcaAdminDashboard = lazy(() => import("../pages/PcaAdmin/Dashboard"));
const SearchCandidates = lazy(() => import("../pages/PcaAdmin/SearchCandidates"));
const pcaCandidateList = lazy(() => import("../pages/PcaAdmin/CandidateList"));
const UploadProfiles = lazy(() => import("../pages/PcaAdmin/UploadProfiles"));
const PcaUserManagement = lazy(() => import("../pages/PcaAdmin/UserManagement"));
const PcaReports = lazy(() => import("../pages/PcaAdmin/Reports"));
const SalaryMeterLayout = lazy(() => import("../pages/SalaryMeterLayout"));
const Assessments = lazy(() => import("../pages/Assessments"));
const CandidateFeedback = lazy(() => import("../pages/Admin/CandidateFeedback"));
const ResumeBuilderIndex = lazy(() =>
  import("../components/ResumeBuilder/Resume-Builder-Index")
);
const ResumeDisplay = lazy(() => import("../components/ResumeBuilder/ResumeDisplay"));
const Analytics = lazy(() => import("../pages/Admin/Analytics"));
const GetAssessed = lazy(() => import("../pages/GetAssessed"));
const AdminAssessment = lazy(() => import("../pages/Admin/AdminAssessment"));
const CreateAssessment = lazy(() => import("../pages/Admin/CreateAssessment"));
const AssessmentDetails = lazy(() => import("../pages/Admin/AssessmentDetails"));
const QuickAssessment = lazy(() => import("../pages/QuickAssessment"));
const CreateAssessmentLevel = lazy(() => import("../pages/Admin/CreateAssessmentLevel"));
const SurveyQuestion = lazy(() => import("../components/Survey/SurveyQuestionForm"));
const Events = lazy(() => import("../pages/Admin/Events"));
const Mentoring = lazy(() => import("../components/Mentoring/mentoring"));
const MockInterview = lazy(() => import("../components/MockInterview/mock-interview"));
const AdminEventsCreate = lazy(() => import("../pages/Admin/AdminEventsCreate"));
const RecruitmentEvents = lazy(() => import("../pages/RecruitmentEvents"));
const EventDetails = lazy(() => import("../pages/Admin/EventDetails"));
const UpSkilling = lazy(() => import("../components/UpSkilling"));
const RecruiterEvents = lazy(() => import("../pages/Recruiter/Events/RecruiterEvent"));


/**
 * ⚠ These are internal routes!
 * They will be rendered inside the app, using the default `containers/Layout`.
 * If you want to add a route to, let's say, a landing page, you should add
 * it to the `App`'s router, exactly like `Login`, `CreateAccount` and other pages
 * are routed.
 *
 * If you're looking for the links rendered in the SidebarContent, go to
 * `routes/sidebar.js`
 */
const routes = [
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/my-interview",
    component: MyInterview,
  },
  {
    path: "/profile/*",
    component: Profile,
  },
  {
    path: "/jobs/search",
    component: SearchJobs,
  },
  {
    path: "/jobs/*",
    component: Jobs,
  },
  {
    path: "/invites/*",
    component: Invites,
  },
  {
    path: "/interviews/*",
    component: Interviews,
  },
  {
    path: "/resume-tools",
    component: QuickResume,
  },
  {
    path: "/quick-assessment",
    component: QuickAssessment,
  },
  {
    path: "/assessments",
    component: Assessments,
  },
  {
    path: "/support",
    component: SupportHistory,
  },
  {
    path: "/admin/candidate/support",
    component: AdminSupportHistory,
  },
  {
    path: "/admin/sales",
    component: AdminSalesTicket,
  },
  {
    path: "/admin/dashboard",
    component: AdminDashboard,
  },
  {
    path: "/admin/skill-taxonomy",
    component: SkillTaxonomy,
  },
  {
    path: "/admin/skill-taxonomy/view",
    component: ViewSkills,
  },
  {
    path: "/admin/skill-taxonomy/:status",
    component: ReviewTaxonomy,
  },
  {
    path: "/admin/skill-taxonomy/upload",
    component: UploadSkill,
  },
  {
    path: "/admin/skill-taxonomy/add-skill",
    component: AddSkill,
  },
  {
    path: "/admin/skill-taxonomy/add-category",
    component: AddCategory,
  },
  {
    path: "/admin/support",
    component: SupportTickets,
  },
  {
    path: "/admin/recruiter/support",
    component: RecruiterSupport,
  },
  {
    path: "/admin/customer/create",
    component: CreateCustomer,
  },
  {
    path: "/admin/accounts",
    component: AccountManagement,
  },
  {
    path: "/admin/account-management/:id",
    component: AdminAccountManagement,
  },
  { path: "/admin/alerts", component: Alerts },
  { path: "/admin/reports", component: AdminReportsTab },
  { path: "/admin/whats-new", component: WhatsNew },
  { path: "/admin/candidate/search", component: CandidateProfileSearch },
  {
    path: "/admin/candidate-list",
    component: CandidateList,
  },
  {
    path: "/admin/candidate-search-by-email",
    component: CandidateSearchEmail,
  },
  // { path: "/admin/candidate/search", component: CandidateSearchPromt },

  { path: "/admin/candidate/profile", component: CandidateProfileSearch },

  { path: "/admin/job/:action", component: AdminJobPosting },
  { path: "/admin/job/:action/:jobId", component: AdminJobPosting },
  { path: "/admin/job/list", component: AdminJobList },
  { path: "/admin/assessments", component: AdminAssessment },
  { path: "/admin/assessment/:action", component: CreateAssessment },
  { path: "/admin/assessment/:action/:assessmentId", component: CreateAssessment },

  { path: "/admin/assessment-details/:assessmentId/:an", component: AssessmentDetails },
  { path: "/admin/assessment/level", component: CreateAssessmentLevel },

  { path: "/recruiter/assessment/:action", component: CreateAssessment },

  { path: "/recruiter/assessments", component: AdminAssessment },
  {
    path: "/recruiter/events",
    component: RecruiterEvents,
  },
  { path: "/recruiter/assessment/:action", component: CreateAssessment },
  { path: "/recruiter/assessment/:action/:assessmentId", component: CreateAssessment },

  {
    path: "/recruiter/assessment-details/:assessmentId/:an",
    component: AssessmentDetails,
  },

  {
    path: "/recruiter/support",
    component: RecruiterSupportHistory,
  },
  {
    path: "/recruiter/joblist",
    component: JobList,
  },
  {
    path: "/recruiter/billing",
    component: BillingPage,
  },
  {
    path: "/rolemanagement/role/add",
    component: AddNewRole,
  },
  {
    path: "/rolemanagement/editrole",
    component: EditNewRole,
  },
  {
    path: "/rolemanagement/users/add",
    component: AddUser,
  },
  {
    path: "/rolemanagement/users/edit",
    component: EditUser,
  },
  {
    path: "/rolemanagement",
    component: ListingRole,
  },
  {
    path: "/rolemanagement/users",
    component: ListingUsers,
  },

  { path: "/recruiter/job/:action", component: JobPostingForm },
  { path: "/recruiter/job/:action/:jobId", component: JobPostingForm },
  { path: "/recruiter/user", component: UserManagement },
  { path: "/recruiter/profile", component: YourProfile },
  { path: "/recruiter/dashboard", component: RecruiterDashboard },
  // { path: "/recruiter/candidates/recommended", component: CandidateRecommendations },
  { path: "/recruiter/candidates/recommended", component: RecommendedCandidateMainTabs },
  { path: "/recruiter/candidate/search", component: CandidateSearch },
  { path: "/recruiter/reports", component: RecruiterReportsTab },
  { path: "/recruiter/subscription", component: SubscriptionPlan },
  { path: "/success", component: Success },
  { path: "/error", component: Error },
  { path: "/refer-your-friends", component: ReferYourFriends },
  { path: "/interview-tips", component: InterviewTips },
  { path: "/upskilling", component: UpSkilling },
  { path: "/free-resources", component: FreeResources },
  { path: "/pca_admin/dashboard", component: PcaAdminDashboard },
  { path: "/pca_admin/search-candidates", component: SearchCandidates },
  { path: "/pca_admin/candidate-list", component: pcaCandidateList },
  { path: "/pca_admin/upload-profiles", component: UploadProfiles },
  { path: "/pca_admin/users", component: PcaUserManagement },
  { path: "/pca_admin/reports", component: PcaReports },
  { path: "/salary-meter", component: SalaryMeterLayout },
  { path: "/pca_agent/dashboard", component: PcaAdminDashboard },
  { path: "/pca_agent/search-candidates", component: SearchCandidates },
  { path: "/pca_agent/candidate-list", component: pcaCandidateList },
  { path: "/pca_agent/upload-profiles", component: UploadProfiles },
  { path: "/pca_agent/reports", component: PcaReports },
  { path: "/admin/feedback", component: CandidateFeedback },
  { path: "/resume-builder", component: ResumeBuilderIndex },
  { path: "/resume-display", component: ResumeDisplay },
  { path: "/survey-questions", component: SurveyQuestion },
  { path: "/mentoring", component: Mentoring },
  { path: "/mock-interview", component: MockInterview },
  {
    path: "/admin/analytics",
    component: Analytics,
  },
  {
    path: "/getassessed/:assessmentId",
    component: GetAssessed,
  },
  {
    path: "/admin/events",
    component: Events,
  },
  {
    path: "/admin/events/:action",
    component: AdminEventsCreate,
  },
  {
    path: "/admin/events/:action/:eventId",
    component: AdminEventsCreate,

  },
  { path: "/admin/event-details/:eventId/:en", component: EventDetails },
  {
    path: "/recruitment-events",
    component: RecruitmentEvents,
  },
];

export default routes;

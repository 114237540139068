//import { t } from "i18next";
import i18n from "../translations/i18n";
let t = i18n.t;
export const convertToDateObject = (dateString) => {
  let date, month, year;
  if (dateString) {
    let newFormat = dateString.split("-");
    if (newFormat.length === 2) {
      month = newFormat[0];
      year = newFormat[1];
    } else if (newFormat.length === 3) {
      date = newFormat[0];
      month = newFormat[1];
      year = newFormat[2];
    }
    return new Date(year, month - 1);
  }
};

/////////////////////Styles///////////////////////
export const templates = {
  candidate: "Candidate",
  recruiter: "Recruiter",
  platformAdmin: "Platform Admin",
  pcaAdmin: "PCA Admin",
  pcaAgent: "PCA Agent",
};
////////////////////////////////////////////////////

export const commonRegex = /^[^<>+$;'"\\/~|:]+$/;
export const specialRegex = /^[^<>$\\~|]+$/;
export const passwordRegex = /[!@^*+()_=\\,.?\\-]+/;
export const passwordRestrictedRegex = /^[^<>&;$#%"'`/:|[\]{}]+$/;
export const onlySpaceRegex = /(?=.*[a-z])|(?=.*[A-Z])/;
export const alphanumericRegex = /^(?=.*[a-zA-Z])(?=.*\d).+$/;
export const linkedinRegex = /https:\/\/(?:[a-z]+\.)?linkedin\.com\/[a-zA-Z0-9-]+/;
export const websiteRegex =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

export const alphaAndSpaceRegex = /^[A-Za-z][A-Za-z ]*$/;
export const onlyNumericsRegex = /^\d+$/; // only numbers are allowed

export const onlyNumSpaceNotAllowed =
  /^(?=.*[a-zA-Z])[a-zA-Z0-9_\s!@#$%^&*()\-+=~"`'<>,./?;:{}[\]|\\]*$/; //Only space,only special char,only numerics not allowed.Alphabets with space,numbers and special chars allowed.

//export const websiteRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/;
export const passwordAllowedCharacters = " !@^*+()_=\\,.?\\-";
export const onlyZerosNotAllowedRegex = /^(?!0+$)[0-9]*$/;
export const onlyZerosRegex = /^(?!0+$)[0-9A-Za-z]/;
export const invalidPINRegex = /^(?![-\s])(?![A-Za-z]+$)(?=.*[0-9])[0-9A-Za-z\s-]*$/;
export const roleOptions = [
  { label: t("_Individual_Contributor"), value: 1 },
  { label: t("_Manager"), value: 2 },
  { label: t("_Consultant"), value: 3 },
];

export const education = [
  { label: t("_Certification"), value: "Certificate" },
  { label: t("_Internship"), value: "Internship" },
];

export const referrerRelationshipMenu = [
  { value: "Former Employer", label: t("_Former_Employer") },
  { value: "Colleague", label: t("_Colleague") },
  { value: "Coaches", label: t("_Coaches") },
  { value: "Advisor", label: t("_Advisor") },
  { value: "Teachers/Professor", label: t("_Teachers/Professor") },
  { value: "clients", label: t("_Clients") },
  {
    value: "Volunteer organization leaders",
    label: t("_Volunteer_organization_leaders"),
  },
];

export const companyOptions = [
  { label: t("_Individual_Contributor"), value: 1 },
  { label: t("_Manager"), value: 2 },
  { label: t("_Consultant"), value: 3 },
];

// "Fulltime | Parttime | Permanant | Temporary | Internship | Consultant | Trainee | "
export const employmentTypeOptions = [
  { label: t("_Full_time"), value: "fulltime" },
  { label: t("_Part_time"), value: "parttime" },
  { label: t("_Permanent"), value: "permanant" },
  { label: t("_Temporary"), value: "temporary" },
  { label: t("_Internship"), value: "internship" },
  { label: t("_Consultant"), value: "consultant" },
  { label: t("_Trainee"), value: "trainee" },
  { label: t("_Freelancer"), value: "freelancer" },
];

const experienceData = [{ label: t("_Fresher_(_Less_than_1_year_)"), value: 0 }];

for (let i = 1; i <= 50; i++) {
  const label = i === 1 ? `${i} ${t("_Year")}` : `${i} ${t("_Years")}`;
  // const value = i === 1 ? `${i} Year` : `${i} Years`;
  const value = i === 1 ? i : i;
  experienceData.push({ label, value });
}

export { experienceData };

export const recentlyPostedData = [
  { label: t("_Last_24_hours"), value: 1 },
  { label: t("_Last_7_days"), value: 7 },
  { label: t("_Last_14_days"), value: 14 },
];

// Onsite | Remote | Hybrid
export const onSiteRemoteOptions = [
  { label: t("_In_-_Office"), value: "onsite" },
  { label: t("_Remote"), value: "remote" },
  { label: t("_Hybrid"), value: "hybrid" },
];

export const interviewNotes = [
  t("_The_interviews_will_be_conducted_for_a_period_of_45min_to_1_hour."),
  t("_Meeting_link_will_be_sent_to_your_email_address."),
  t(
    "_Accept_terms_and_conditions_on_data_privacy_and_confidentiality_to_attend_the_interview."
  ),
  t("_Check_Microphone,_Camera,_Flash_Player,_Browsers,_Devices_for_effectiveness."),
  t("_Display_identity_proof_to_the_interviewer: PAN/PASSPORT/DL/AADHAR."),
  t("_Ensure_proper_front_lighting_for_clear_visibility_to_the_interviewer."),
];
export const paginationData = { postsPerPage: 10 };
export const category = { search: 0, recommended: 1, applied: 2, saved: 3 };

export const completeInterviewTabs = [
  { label: t("_Mock_Interviews"), value: "mock" },
  { label: t("_Company_Interviews"), value: "company" },
];

// Drop down Immediate, 2 weeks, 4 weeks, 6 weeks, 8 weeks
export const noticePeriodMenu = [
  { value: 1, label: t("_Immediate") },
  { value: 15, label: t("_15_Days") },
  { value: 30, label: t("1_Month") },
  { value: 60, label: t("2_Months") },
  { value: 90, label: t("3_Months") },
  // { value: "Immediate", label: t("_Immediate") },
  // { value: "2 weeks", label: t("_2_weeks") },
  // { value: "4 weeks", label: t("_4_weeks") },
  // { value: "6 weeks", label: t("_6_weeks") },
  // { value: "8 weeks", label: t("_8_weeks") },
];

export const supportCategoryOptions = [
  { value: "Login", label: t("_Login") },
  { value: "Password", label: t("_Password") },
  { value: "Username", label: t("_Username") },
];

export const ticketStatusOptions = [
  { value: "Open", label: t("_Open") },
  { value: "Awaiting information", label: t("_Awaiting_information") },
  { value: "Unable to connect", label: t("_Unable_to_connect") },
  { value: "Need more info", label: t("_Need_more_info") },
  { value: "Pending", label: t("_Pending") },
  { value: "Resolved", label: t("_Resolved") },
];

export const createdDateOptions = [
  { value: "All", label: t("_All") },
  { value: "1", label: t("_Last_24_hrs") },
  { value: "7", label: t("_Last_7_days") },
  { value: "14", label: t("_Last_14_days") },
  { value: "30", label: t("_Last_30_days") },
];

export const maximumResumeDownloadCount = 3;

export const filterTypes = {
  companyList: 1,
  location: 2,
  employmentType: 3,
  workLocation: 4,
  experience: 5,
  skill: 6,
  recentlyPosted: 7,
  industry: 8,
};
export const jobStatus = {
  unApplied: 0,
  applied: 1,
  unSaved: false,
  saved: 1,
};
export const pagination = {
  pageSize: 10,
};

export const candidateListPagination = {
  pageSize: 10,
};

export const interviewDetails = {
  pageSize: 10,
};

export const tablePageSize = 4;

// last 24hrs, last 7 days, last 30days and older
export const ticketCreatedDateOptions = [
  { label: t("_Last_24_hrs"), value: "1" },
  { label: t("_Last_7_days"), value: "7" },
  { label: t("_Last_30_days_and_older"), value: "30" },
];

//  Email, Phone, In person
export const modeOfCommOptions = [
  { label: t("_Email"), value: "Email" },
  { label: t("_Phone"), value: "Phone" },
  { label: t("_In_person"), value: "In person" },
];

export const workPlaceTypeOptions = [
  { value: "onsite", label: t("_In-Office") },
  { value: "remote", label: t("_Remote") },
  { value: "hybrid", label: t("_Hybrid") },
];

const positionsData = [];
for (let i = 1; i <= 100; i++) {
  positionsData.push(i);
}
export { positionsData };

export const UserManagementTab = [
  { label: t("_Master_Recruiters"), value: "masterrecruiters" },
  { label: t("_Recruiters"), value: "recruiters" },
];

export const pcaUserManagementTab = [
  { label: t("_Pca_Admin"), value: "pcaadmin" },
  { label: t("_Pca_Agent"), value: "pcaagent" },
];

export const ReportsTabs = [
  { label: t("_Predefined_Reports"), value: "predefinedreports" },
  { label: t("_Custom_Reports"), value: "custom" },
  { label: t("_Interview_Details"), value: "interview" },
  { label: t("_Candidate_Survey"), value: "survey" },
];

export const recruiterReportsTabs = [
  { label: t("_Predefined_Reports"), value: "predefinedreports" },
  { label: t("_Custom_Reports"), value: "custom" },
  { label: t("_Interview_Details"), value: "interview" },
]

export const SearchProfile = [
  { label: t("_Interviewed_Candidates"), value: "smart" },
  { label: t("_Not_Interviewed_Candidates"), value: "unassessed" },
  { label: t("_Saved"), value: "saved" },
];

export const Billing = [
  { label: t("_Billing_History"), value: "billing" },
  { label: t("_Current_Subscription"), value: "subscription" },
];

export const ratedOptions = [
  {
    label: t("_Last_30_days"),
    value: 30,
  },
  {
    label: t("_Last_60_days"),
    value: 60,
  },
  {
    label: t("_Last_90_days"),
    value: 90,
  },
];
export const genderOptions = [
  {
    label: t("_Male"),
    value: "male",
  },
  {
    label: t("_Female"),
    value: "female",
  },
  {
    label: t("_Others"),
    value: "others",
  },
];

export const genderOptionsCandidateSearch = [
  {
    label: t("_Male"),
    value: "male",
  },
  {
    label: t("_Female"),
    value: "female",
  },
  {
    label: t("_Others"),
    value: "other",
  },
];
export const candidateCategoryOptions = [
  {
    label: t("_Interviewed"),
    value: 3,
  },
  {
    label: t("_Not_Interviewed"),
    value: 2,
  },
  // {
  //   label: t("_Fresh_Profiles"),
  //   value: 1,
  // },
];

export const recommendationRangeOptions = [
  { value: "0-20", label: "0-20%" },
  { value: "21-40", label: "21-40%" },
  { value: "41-60", label: "41-60%" },
  { value: "61-80", label: "61-80%" },
  { value: "81-100", label: "81-100%" },
];

export const ProfileTab = [
  { label: t("_Contact_Details"), value: "contactdetails" },
  { label: t("_Company_Details"), value: "companydetails" },
  { label: t("_Reset_Password"), value: "resetpassword" },
];

export const skillLevelOptions = [
  // { label: t("_Advanced"), value: 1 },
  // { label: t("_Basic"), value: 2 },
  // { label: t("_Intermediate"), value: 3 },
  { label: t("_Novice"), value: 1 },
  { label: t("_Competent"), value: 2 },
  { label: t("_Proficient"), value: 3 },
  { label: t("_Expert"), value: 4 },
  { label: t("_Master"), value: 5 },
];

export const mskillLevelOptions = [
  { label: t("_Novice"), value: 1 },
  { label: t("_Competent"), value: 2 },
  { label: t("_Proficient"), value: 3 },
];

export const pskillLevelOptions = [
  { label: t("_Proficient"), value: 3 },
  { label: t("_Expert"), value: 4 },
  { label: t("_Master"), value: 5 },
];

export const recEmploymentTypeOptions = [
  { label: t("_Full_time"), value: "fulltime" },
  { label: t("_Part_time"), value: "parttime" },
  { label: t("_Permanent"), value: "permanant" },
  { label: t("_Temporary"), value: "temporary" },
  { label: t("_Internship"), value: "internship" },
  { label: t("_Consultant"), value: "consultant" },
  { label: t("_Trainee"), value: "trainee" },
  { label: t("_Freelancer"), value: "freelancer" },
];

export const jdEmploymentTypeOptions = {
  "full-time": "fulltime",
  "part-time": "parttime",
  permanent: "permanant",
  temporary: "temporary",
  internship: "internship",
  consultant: "consultant",
  trainee: "trainee",
  freelancer: "freelancer8",
};

const recruiterExperienceData = [
  { label: t("_Fresher_(_Less_than_1_year_)"), value: "0" },
];

for (let i = 1; i <= 50; i++) {
  const label = i === 1 ? `${i} ${t("_Year")}` : `${i} ${t("_Years")}`;
  const value = i;
  recruiterExperienceData.push({ label, value });
}

export { recruiterExperienceData };

export const jdStatusDisplay = {
  0: t("_Saved"),
  1: t("_Open"),
  2: t("_Closed"),
};

export const hotJobStatus = {
  true: t("_View"),
  false: t("_Enable"),
};

export const jdStatusValues = {
  saved: "0",
  open: "1",
  closed: "2",
};

export const jdStatusOptions = [
  {
    label: t("_Saved"),
    value: 0,
  },
  {
    label: t("_Open"),
    value: 1,
  },
  {
    label: t("_Closed"),
    value: 2,
  },
];

export const roles = {
  master_recruiter: "master_recruiter",
  sub_recruiter: "sub_recruiter",
  PlatformAdmin: "Platform Admin",
};
export const recruiter_type = {
  masterRecruiter: "1",
  recruiter: "2",
  recruiter_group_id: "9befac84-85f3-4ce0-a087-5e16a02f5a70",
};

export const recruiterPredefinedReports = [
  { label: t("_Job_Posting_Performance_Report"), value: 1 },
  {
    label: t("_Candidate_Application_Summary_Interviewed"),
    value: 2,
  },
  {
    label: t("_Candidate_Application_Summary_Not_Interviewed"),
    value: 3,
  },
  // {
  //   label: t("_Candidate_Application_Summary_Applied"),
  //   value: 4,
  // },
  { label: t("_User_Activity_And_Access_Report"), value: 5 },
];

export const adminPredefinedReports = [
  { label: t("_User_Activity_Report"), value: 1 },
  {
    label: t("_Job_Posting_Report"),
    value: 2,
  },
  {
    label: t("_Candidate_Application_Summary_Interviewed"),
    value: 3,
  },
  {
    label: t("_Candidate_Application_Summary_Not_Interviewed"),
    value: 4,
  },
  {
    label: t("_Candidate_Referral_Report"),
    value: 5,
  },
  {
    label: t("_Candidate_Redeem_Report"),
    value: 6,
  },
  // {
  //   label: t("_Candidate_Application_Summary_Applied"),
  //   value: 5,
  // },
];

export const interviewReports = [
  { label: t("_Interview_Detail_Count"), value: 1 },
  { label: t("_Candidate_Details"), value: 2 },
  {
    label: t("_Downloaded_Profile_Details"),
    value: 3,
  },
];
export const interviewReportsRecruiter = [
  { label: t("_Downloaded_Profiles_Details"), value: 1 },
  { label: t("_Interview_Detail_Count"), value: 2 },
  // {
  //   label: t("_Downloaded_Profile_Details"),
  //   value: 3,
  // },
];
export const salesTicketStatusOptions = [
  { value: "Open", label: t("_Open") },
  { value: "Awaiting information", label: t("_Awaiting_information") },
  { value: "Unable to contact", label: t("_Unable_to_contact") },
  { value: "Need more information", label: t("_Need_more_information") },
  { value: "Customer on boarded", label: t("_Customer_onboarded") },
  { value: "Customer not interested", label: t("_Customer_Not_interested") },
  { value: "In process", label: t("_Discussion_In_process") },
  { value: "Reopen", label: t("_Reopen") },
  { value: "Resolved", label: t("_Resolved") },
];

export const accountStatusOptions = [
  {
    label: t("_All"),
    value: "all",
  },
  {
    label: t("_Active"),
    value: "active",
  },
  {
    label: t("_Inactive"),
    value: "inactive",
  },
];

export const userStatusOptions = [
  {
    label: t("_Active"),
    value: "active",
  },
  {
    label: t("_Inactive"),
    value: "inactive",
  },
];

export const candidateCategory = {
  vsmart: 3,
  unassessed: 2,
  vfresh: 1,
  saved: 4,
  invited: 5,
  screenselect: 6,
  adminSave: 7,
  accepted: 8,
  declined: 9,
  downloaded: 10,
};

export const candidateStatus = {
  general: 0,
  invited: 1,
  accepted: 2,
  screenSelect: 3,
  sharedWithHr: 4,
  declined: 5,
  pushedToInterview: 6,
};

export const recruiterAction = {
  invite: 1,
  screenSelect: 2,
  shareWithHM: 3,
};

export const jobEdit = {
  invited: 1,
  uninvited: 0,
};

export const genderOptionsInterviewDetails = [
  {
    label: t("_Male"),
    value: "1",
  },
  {
    label: t("_Female"),
    value: "0",
  },
  {
    label: t("_Others"),
    value: "2",
  },
];

export function getInterviewStatus(IVStatus) {
  const status = parseInt(IVStatus, 10);
  const statusMap = {
    0: "PENDING",
    1: "COMPLETED",
    2: "CANCELLED",
  };
  return statusMap[status] || "Unknown Status";
}

export const interviewStatusOptions = [
  { label: t("_Pending"), value: "0" },
  { label: t("_Completed"), value: "1" },
  { label: t("_Cancelled"), value: "2" },
];

export const SkillLevel = [
  { label: t("_Novice"), value: "1" },
  { label: t("_Competent"), value: "2" },
  { label: t("_Proficient"), value: "3" },
  { label: t("_Expert"), value: "4" },
  { label: t("_Master"), value: "5" },
];

export const knowledgeLevelData = (
  <div className="text-left font-normal">
    <p>
      <div className="mb-2">
        <span className="font-semibold">1.{t("_Novice")}: </span>
        {t(
          "_Novice_This_level_typically_refers_to_someone_who_is_a_beginner_or_has_limited_experience_in_a_particular_skill_or_field."
        )}
        <br />
      </div>
      <div className="mb-2">
        <span className="font-semibold">2.{t("_Competent")}: </span>
        {t(
          "_Competent_A_competent_individual_has_a_reasonable_level_of_proficiency_and_can_perform_tasks_or_solve_problems_effectively_but_may_not_yet_be_considered_an_expert."
        )}
        <br />
      </div>
      <div className="mb-2">
        <span className="font-semibold">3.{t("_Proficient")}: </span>
        {t(
          "_Proficient_Proficiency_suggests_a_higher_level_of_skill_and_knowledge_than_competence._Proficient_individuals_can_perform_tasks_or_work_in_a_field_with_a_greater_degree_of_skill_and_accuracy."
        )}
        <br />
      </div>
      <div className="mb-2">
        <span className="font-semibold">4.{t("_Expert")}: </span>
        {t(
          "_Expert_An_expert_has_extensive_knowledge_and_experience_in_a_specific_area_and_can_provide_valuable_insights,_solve_complex_problems,_and_often_teach_or_mentor_others."
        )}{" "}
        <br />
      </div>
      <div className="mb-2">
        <span className="font-semibold">5.{t("_Master")}: </span>
        {t(
          "_Master_Masters_are_at_the_highest_level_of_expertise._They_have_achieved_a_level_of_mastery_that_goes_beyond_expertise_and_often_contribute_significantly_to_their_field,_innovate,_and_set_new_standards."
        )}
      </div>
    </p>
  </div>
);

export const knowledgeLevelDataforContactNumber = t(
  "_Ensure_you_give_your_correct_number_to_get_relevant_updates._Whatsapp_notification_will_be_auto_enabled_for_this_number."
);

export const candidateSkillLevel = {
  1: t("_Novice"),
  2: t("_Competent"),
  3: t("_Proficient"),
  4: t("_Expert"),
  5: t("_Master"),
};

export const AlertsRoleOptions = [
  { label: t("_Recruiter"), value: "1" },
  { label: t("_Candidate"), value: "2" },
];

// Monday, Tuesday, Wednesday, Thursday, Friday,Saturday and Sunday
export const WeekDaysOptions = [
  { label: t("_Monday"), value: "Monday" },
  { label: t("_Tuesday"), value: "Tuesday" },
  { label: t("_Wednesday"), value: "Wednesday" },
  { label: t("_Thursday"), value: "Thursday" },
  { label: t("_Friday"), value: "Friday" },
  { label: t("_Saturday"), value: "Saturday" },
  { label: t("_Sunday"), value: "Sunday" },
];

export const subscriptionStatus = [
  { label: t("_All"), value: "all" },
  { label: t("_Active"), value: "active" },
  { label: t("_Inactive"), value: "inactive" },
  { label: t("_Cancel"), value: "cancel" },
];

export const ProfileInfoTooltip = (
  <p>
    {t(
      "_A_profile_picture_can_help_recruiters_establish_a_personal_connection_with_the_candidate._It_can_make_the_resume_feel_more_human_and_relatable,_which_can_be_especially_valuable_when_the_job_involves_a_strong_interpersonal_component_or_client-facing_role."
    )}
    <br />
    <br />
    {t(
      "_Your_profile_video_will_be_used_for_verification_purposes_only_when_an_interview_is_conducted."
    )}
  </p>
);
export const alertRoles = {
  Recruiter: "1",
  Candidate: "2",
};

export const TermsAndConditions = (
  <p>
    {t("_What_is_Curatal?")}
    <br /> <br />
    {t(
      "_Curatal_is_a_brand_owned_by_Progressive_Video_LLP._First_IPO_stands_for_“First_Interview_Process_Outsourcing”._Curatal_manages_the_interview_process_&_also_provides_an_online_Digital_Skill_Evaluation_platform_offering_audio/video-based_anytime-anywhere_interview_services_globally._The_services_include_online_/_offline_skill_evaluation,_personality_profiling_&_personal/professional_development_advisory_in_the_form_of_coaching_&_mentoring."
    )}
    <br /> <br />
    {t(
      "_Curatal_aims_to_be_the_foremost_Public_Skill_Evaluation_Platform_that_the_industry_can_leverage_in_their_search_for_Quality_Talent."
    )}
    <br /> <br />
    {t("_What_Personal_Information_we_Capture?")}
    <br /> <br />
    {t("_Skills,_Employment_History")}
    <br /> <br />
    {t("_Name,_Phone_number,_Demographics,_Gender,_and_Email.")}
    <br /> <br />
    {t(
      "_Internet_Protocol_(IP)_address,_cookies,_location,_browser_type,_access_time,_error_log"
    )}
    <br /> <br />
    {t(
      "_Video_profile_and_video_interview,_which_may_include_your_bodily_image_and_voice,_as_well_as_your_surroundings"
    )}
    <br /> <br />
    {t("_Information_You_Choose_to_Display_Publicly_on_the_Network")}
    <br /> <br />
    {t(
      "_If_you_decide_to_post_personal_information_in_the_public_parts_of_our_sites,_it_is_public._Some_users_may_elect_to_publicly_post_personally_identifying_or_sensitive_information_about_themselves_in_their_normal_use_of_the_network."
    )}
    <br /> <br />
    {t(
      "_This_could_occur_through_the_use_of_the_optional_profile_fields,_in_question_or_answer_posts,_or_when_an_individual_posts,_etc._Information_like_that,_which_is_voluntarily_posted_in_publicly_visible_parts_of_the_network,_is_considered_to_be_public."
    )}
    <br /> <br />
    {t("_Where_do_we_store_this_Data_?")}
    <br /> <br />
    {t("_All_First_IPO_data_is_stored_in_secured_Amazon_Cloud_storage")}
    <br /> <br />
    {t("_What_do_we_do_to_protect_your_Personal_Information?")}
    <br /> <br />
    {t(
      "_Few_specific_controls_that_we_have_implemented_to_protect_your_Personal_Information_are"
    )}
    <br /> <br />
    {t("_Role-based_access_control.")}
    <br /> <br />
    {t("_Row-level_security_within_the_database.")}
    <br /> <br />
    {t("_Encryption_for_data_at_rest_and_during_transit.")}
    <br /> <br />
    {t("_Access_to_videos_and_documents_using_shared access signature.")}
    <br /> <br />
    {t(
      "_Continuous_control_monitoring_(vulnerability_assessment_and_penetration_testing_at_each_stage_of_SDLC)"
    )}
    <br /> <br />
    {t("_How_do_we_comply_with_Privacy_Regulations?")}
    <br /> <br />
    {t("_Specific_elements_of_our_privacy_framework_include")}
    <br /> <br />
    {t("_Governance_and_management")}
    <br /> <br />
    {t(
      "_Curatal's_Privacy_Policy_is_based_on_global_regulations_that_govern_our_operations."
    )}
    <br /> <br />
    {t(
      "_Curatal_has_appointed_a_data_privacy_consultant_to_oversee_the_privacy_framework,_operations,_compliance,_and_monitoring."
    )}
    <br /> <br />
    {t(
      "_Curatal_has_in_place_data_transfer_agreements_across_its_global_entities_that_govern_data_transfer,_processing,_and_control."
    )}
    <br /> <br />
    {t(
      "_Curatal_has_put_in_place_a_program_to_conduct_risk_assessments_and_identify_and_adhere_to_privacy_requirements_whenever_it_enters_a_new_territory._(While_evaluating_candidates_in_any_other_country_other_than_India_we_seek_the_permission_of_the_participant_to_video_record_the_discussion)"
    )}
    <br /> <br />
    {t("_Should_you_have_any_questions,_please_reach_out_to_our_Data_Privacy_Officer_at")}
    <br /> <br />
    {t("_reach@curatal.com")}
  </p>
);

export const PrivacyPolicy = (
  <p>
    {t("_Who_Are_We")}
    <br /> <br />
    {t("_Our_website_address_is: https://curatal.com")}
    <br /> <br />
    {t("_What_personal_data_we_collect_and_why_we_collect_it")}
    <br /> <br />
    {t("_Comments")}
    <br /> <br />
    {t(
      "_When_visitors_leave_comments_on_the_site_we_collect_the_data_shown_in_the_comments_form,_and_also_the_visitor's_IP_address_and_browser_user_agent_string_to_help_spam_detection."
    )}
    <br /> <br />
    {t(
      "_An_anonymized_string_created_from_your_email_address_(also_called_a_hash)_may_be_provided_to_the_Gravatar_service_to_see_if_you_are_using_it._The_Gravatar_service_privacy_policy_is_available_here"
    )}
    {t(
      "_After_approval_of_your_comment,_your_profile_picture_is_visible_to_the_public_in_the_context_of_your_comment."
    )}
    <br /> <br />
    {t("_Media")}
    <br /> <br />
    {t(
      "_If_you_upload_images_to_the_website,_you_should_avoid_uploading_images_with_embedded_location_data_(EXIF_GPS)_included._Visitors_to_the_website_can_download_and_extract_any_location_data_from_images_on_the_website."
    )}
    <br /> <br />
    {t("_Cookies")}
    <br /> <br />
    {t(
      "_If_you_leave_a_comment_on_our_site_you_may_opt-in_to_saving_your_name,_email_address_and_website_in_cookies._These_are_for_your_convenience_so_that_you_do_not_have_to_fill_in_your_details_again_when_you_leave_another_comment._These_cookies_will_last_for_one_year."
    )}
    <br /> <br />
    {t(
      "_If_you_visit_our_login_page,_we_will_set_a_temporary_cookie_to_determine_if_your_browser_accepts_cookies._This_cookie_contains_no_personal_data_and_is_discarded_when_you_close_your_browser._When_you_log_in,_we_will_also_set_up_several_cookies_to_save_your_login_information_and_your_screen_display_choices._Login_cookies_last_for_two_days,_and_screen_options_cookies_last_for_a_year._If_you_select_“Remember_Me”,_your_login_will_persist_for_two_weeks._If_you_log_out_of_your_account,_the_login_cookies_will_be_removed."
    )}
    <br /> <br />
    {t(
      "_If_you_edit_or_publish_an_article,_an_additional_cookie_will_be_saved_in_your_browser._This_cookie_includes_no_personal_data_and_simply_indicates_the_post_ID_of_the_article_you_just_edited._It_expires_after_1_day."
    )}
    <br /> <br />
    {t("_Embedded_content_from_other_websites")}
    <br /> <br />
    {t(
      "_Articles_on_this_site_may_include_embedded_content_(e.g._videos,_images,_articles,_etc.)._Embedded_content_from_other_websites_behaves_in_the_exact_same_way_as_if_the_visitor_has_visited_the_other_website."
    )}
    <br /> <br />
    {t(
      "_These_websites_may_collect_data_about_you,_use_cookies,_embed_additional_third-party_tracking,_and_monitor_your_interaction_with_that_embedded_content,_including_tracking_your_interaction_with_the_embedded_content_if_you_have_an_account_and_are_logged_in_to_that_website."
    )}
    <br /> <br />
    {t("_Analytics")}
    <br /> <br />
    {t("_How_long_we_retain_your_data")}
    <br /> <br />
    {t(
      "_If_you_leave_a_comment,_the_comment_and_its_metadata_are_retained_indefinitely._This_is_so_we_can_recognize_and_approve_any_follow-up_comments_automatically_instead_of_holding_them_in_a_moderation_queue._For_users_that_register_on_our_website_(if_any),_we_also_store_the_personal_information_they_provide_in_their_user_profile._All_users_can_see,_edit,_or_delete_their_personal_information_at_any_time_(except_they_cannot_change_their_username)._Website_administrators_can_also_see_and_edit_that_information."
    )}
    <br /> <br />
    {t("_What_rights_you_have_over_your_data")}
    <br /> <br />
    {t(
      "_If_you_have_an_account_on_this_site,_or_have_left_comments,_you_can_request_to_receive_an_exported_file_of_the_personal_data_we_hold_about_you,_including_any_data_you_have_provided_to_us._You_can_also_request_that_we_erase_any_personal_data_we_hold_about_you._This_does_not_include_any_data_we_are_obliged_to_keep_for_administrative,_legal,_or_security_purposes."
    )}
    <br /> <br />
    {t("_Where_we_send_your_data")}
    <br /> <br />
    {t("_Visitor_comments_may_be_checked_through_an_automated_spam_detection_service.")}
  </p>
);

export const TermsAndConditionsPostLogin = (
  <p>
    {t("_What_is_Curatal?")}
    <br /> <br />
    {t(
      "_Curatal_is_a_brand_owned_by_Progressive_Video_LLP._First_IPO_stands_for_“First_Interview_Process_Outsourcing”._Curatal_manages_the_interview_process_&_also_provides_an_online_Digital_Skill_Evaluation_platform_offering_audio/video-based_anytime-anywhere_interview_services_globally._The_services_include_online_/_offline_skill_evaluation,_personality_profiling_&_personal/professional_development_advisory_in_the_form_of_coaching_&_mentoring."
    )}
    <br /> <br />
    {t(
      "_Curatal_aims_to_be_the_foremost_Public_Skill_Evaluation_Platform_that_the_industry_can_leverage_in_their_search_for_Quality_Talent."
    )}
    <br />
  </p>
);

export const PrivacyPolicyPostLogin = (
  <p>
    {t("_Who_Are_We")}
    <br /> <br />
    {t("_Our_website_address_is: https://curatal.com")}
    <br /> <br />
    {t("_What_personal_data_we_collect_and_why_we_collect_it")}
    <br /> <br />
    {t("_Comments")}
    <br /> <br />
    {t(
      "_When_visitors_leave_comments_on_the_site_we_collect_the_data_shown_in_the_comments_form,_and_also_the_visitor's_IP_address_and_browser_user_agent_string_to_help_spam_detection."
    )}
  </p>
);
export const ticketMessaging = {
  RecruiterTicket: "1",
  SalesTicket: "2",
  CandidateTicket: "3",
};

export const notificationMessages = [
  {
    name: "SP",
    title: "A candidate has accepted your invite",
    date: "01 Dec 2023",
    time: "12 : 09 PM",
  },
  {
    name: "A",
    title: "Your Jd has been poste din Curatal.com for candidtaes to apply",
    date: "01 Dec 2023",
    time: "12 : 09 PM",
  },
  {
    name: "DD",
    title: "A candidate has accepted your invite",
    date: "01 Dec 2023",
    time: "12 : 09 PM",
  },
  {
    name: "N",
    title: "Your Jd has been poste din Curatal.com for candidtaes to apply",
    date: "01 Dec 2023",
    time: "12 : 09 PM",
  },
  {
    name: "K",
    title: "You have a message from CSR for your ticket^^",
    date: "01 Dec 2023",
    time: "12 : 09 PM",
  },
  {
    name: "KP",
    title: "A candidate has accepted your invite",
    date: "01 Dec 2023",
    time: "12 : 09 PM",
  },
  {
    name: "ST",
    title: "Your Jd has been poste din Curatal.com for candidtaes to apply",
    date: "01 Dec 2023",
    time: "12 : 09 PM",
  },
  {
    name: "SD",
    title: "A candidate has accepted your invite",
    date: "01 Dec 2023",
    time: "12 : 09 PM",
  },
  {
    name: "DS",
    title: "You have a message from CSR for your ticket^^",
    date: "01 Dec 2023",
    time: "12 : 09 PM",
  },
];

export const candidateResumeFlag = {
  quickResumeWithoutPII: 1,
  quickResumeWithPII: 2,
  actualResume: 3,
};

export const inviteStatus = {
  invitedJobs: 1,
  declinedJobs: 2,
};

export const candidateListOption = [
  { label: t("_Active"), value: 1 },
  { label: t("_Inactive"), value: 2 },
];

export const candidateTypeOption = [
  { label: "_Not_Interviewed", value: 2 },
  { label: "_Interviewed", value: 3 },
];

export const inviteValidDays = 3;

export const itemsPerPageOptions = [
  { label: "10", value: 10 },
  { label: "25", value: 25 },
  { label: "50", value: 50 },
  { label: "75", value: 75 },
  { label: "100", value: 100 },
];

export const mapWorkStatus = (status) => {
  if (status === "1") {
    return t("_Claimed");
  } else if (status === "0") {
    return t("_Released");
  } else {
    return "";
  }
};

export const mapInviteStatus = (status) => {
  if (status === "0") {
    return t("_Not_Invited");
  } else if (status === "1") {
    return t("_Invited");
  } else if (status === "2") {
    return t("_Activated");
  } else {
    return "";
  }
};

export const pcaUserTypes = {
  pcaAdmin: 1,
  pcaAgent: 2,
};

export const CandidateStatusOptions = [
  {
    value: "1",
    label: t("_Looking_for_a_Job"),
    description: t("_description_Looking_for_a_Job"),
  },
  {
    value: "2",
    label: t("_Casually_Looking_for_a_Job"),
    description: t("_description_Casually_Looking_for_a_Job"),
  },
  {
    value: "3",
    label: t("_Not_looking_for_a_job"),
    description: t("_description_Not_looking_for_a_job"),
  },
];

export const ContentDetails = [
  { value: "Admin", label: t("_Admin") },
  { value: "Master Recruiters", label: t("_Master_Recruiters") },
  { value: "Recruiters", label: t("_Recruiters") },
  { value: "Candidate", label: t("Candidate") },
  { value: "PCA Admin", label: t("_Pca_Admin") },
];
export const SelectCategories = [
  { value: "New Feature", label: t("_New_feature") },
  { value: "Enhancement", label: t("_Enhancement") },
  { value: "Announcemnt", label: t("_Announcemnt") },
  { value: "News letter", label: t("_News_letter") },
];
export const unsubscriptionReasons = [
  t("_I_no_longer_want_to_receive_these_emails"),
  t("_The_content_is_not_relevant_to_me"),
  t("_I_receive_too_many_emails"),
  t("_I_didn't_sign_up_for_these_emails"),
  t("_Emails_are_too_frequent"),
  t("_I’m_switching_to_a_different_service"),
  t("_I_prefer_to_follow_updates_on_social_media"),
  t("_Other_(please_specify)"),
];

export const analyticsOptions = [
  { value: 0, label: t("_Skills") },
  { value: 1, label: t("_Assessed_Skills") },
  { value: 2, label: t("_Experience") },
  { value: 3, label: t("_Preferred_Location") },
  { value: 4, label: t("_Expected_Salary") },
  { value: 5, label: t("_Current_Salary") },
  { value: 6, label: t("_Notice_Period") },
  { value: 7, label: t("_Gender") },
];

export const analyticsJobTrendsOptions = [
  { value: 0, label: t("_Skills") },
  { value: 1, label: t("_Experience") },
  { value: 2, label: t("_Location") },
  { value: 3, label: t("_Salary") },
  { value: 4, label: t("_Notice_Period") },
];

export const analyticsTicketOptions = [
  { value: 0, label: t("_Business") },
  { value: 1, label: t("_Sales") },
  { value: 2, label: t("_Candidate") },
];

export const AnalyticsCandidateStatus = {
  active: 1,
};

export const AnalyticsCandidateType = {
  interviewed: 3,
  notInterviewed: 2,
};

// Role name for whatsnew
export const role_name = [
  { value: "candidate", label: t("_Candidate") },
  { value: "master_recruiter", label: t("_Master_recruiter") },
  { value: "sub_recruiter", label: t("_Sub_recruiter") },
];

export const levelOptions = [
  { value: "easy", label: t("_Beginner") },
  { value: "intermediate", label: t("_Intermediate") },
  { value: "hard", label: t("_Expert") },
];

export const assessmentStatusOptions = [
  { value: "1", label: t("_Active") },
  { value: "2", label: t("_Archive") },
];

export const noOfAssessmentQuestionsOptions = [
  { value: 10, label: "10" },
  { value: 20, label: "20" },
  { value: 30, label: "30" },
];

export const assessmentCategoryOptions = [
  { value: "Coding", label: t("_Coding") },
  { value: "Concept", label: t("_Concept") },
];

export const eventCategoryOptions = [
  { value: "Diversity Hiring Events", label: t("_Diversity_Hiring_Events") },
  { value: "Women in Digital Tech", label: t("_Women_in_Digital_Tech") },
  { value: "Early Joiner Events", label: t("_Early_Joiner_Events") },
  { value: "Others", label: t("_Others") },
];

export const assessmentTypeOptions = [
  { value: "subjective", label: t("_Subjective") },
  { value: "mcq_1", label: t("_MCQ") },
  { value: "true_false", label: t("_True/False") },
  { value: "coding", label: t("_Coding") },
];

export const assessmentquestionsPerTypeOptions = [
  { value: 10, label: "10" },
  { value: 20, label: "20" },
  { value: 30, label: "30" },
];

export const assessmenttimeLimiteOptions = [...Array(100)].map((_, i) => {
  return { value: i + 1, label: i + 1 };
});

export const assessmentNoOfAttemptsOptions = [...Array(5)].map((_, i) => {
  return i !== 4
    ? { value: i + 1, label: i + 1 }
    : { value: "0", label: t("_Unlimited") };
});

export const assessmentQuestionsPerTypeOptions = [...Array(20)].map((_, i) => {
  return { value: i + 1, label: i + 1 };
});

export const assessmentLevelOptions = [...Array(100)].map((_, i) => {
  return { value: (i + 1) * 20, label: (i + 1) * 20 };
});

export const assessmentDetailsTab = [
  { label: t("_Assessment_Details"), value: "assessment-details" },
  { label: t("_Candidate_List"), value: "candidate-list" },
];

export const assessmentScoreRangeOptions = [...Array(10)].map((_, i) => {
  return { value: `${10 * i} - ${10 * (i + 1)}`, label: `${10 * i} - ${10 * (i + 1)}` };
});

export const genderMapping = {
  0: ["male", "female", "other"], // All
  Male: ["male"],
  Female: ["female"],
  "Not Opted": ["other"],
};

export const cities = [
  "Agra",
  "Ahmedabad",
  "Allahabad (Prayagraj)",
  "Bareilly",
  "Bengaluru",
  "Bhopal",
  "Bhubaneswar",
  "Chandigarh",
  "Chandrapur",
  "Chennai",
  "Coimbatore",
  "Dehradun",
  "Delhi",
  "Dhanbad",
  "Faridabad",
  "Guwahati",
  "Gurugram (Gurgaon)",
  "Hyderabad",
  "Indore",
  "Jaipur",
  "Jabalpur",
  "Jodhpur",
  "Kanpur",
  "Kochi",
  "Kolkata",
  "Lucknow",
  "Ludhiana",
  "Madurai",
  "Mangalore",
  "Meerut",
  "Mumbai",
  "Mysuru (Mysore)",
  "Nagpur",
  "Nashik",
  "Noida",
  "Patna",
  "Pune",
  "Raipur",
  "Rajkot",
  "Ranchi",
  "Shimla",
  "Srinagar",
  "Surat",
  "Thane",
  "Trivandrum (Thiruvananthapuram)",
  "Udaipur",
  "Vadodara",
  "Varanasi",
  "Vijayawada",
  "Visakhapatnam",
  "Others",
];

export const eventDetailsTab = [
  { label: t("_Event_Details"), value: "event-details" },
  { label: t("_Candidate_List"), value: "candidate-list" },
];
